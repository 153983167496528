import React, { useState } from 'react';
import * as styles from './employee.module.scss';
import { GatsbyImage } from "gatsby-plugin-image"
import { Heading } from '../../01_atoms/Heading/Heading';
import {motion} from 'framer-motion';
//import { isMobile } from 'react-device-detect';

import cx from 'classnames';

export const Employee = ({children,name,fn, image,bImage, ...props}) => {
    // render html

    const [active, setActive] = useState(false);


    const onClick = (e) => {
        //if(!isMobile) setActive(!active);
        setActive(!active);
    }

    const employeeClasses = cx(
        styles.employee,
        { [`${styles.active}`]: active}
    );


    return (
        <motion.div
            className={employeeClasses}
            onClick={onClick}
        >
            <div className={styles.imageWrapper}>
                <div className={styles.image}>
                    <GatsbyImage image={image} alt={name} />
                </div>
                <div className={styles.bImage}>
                    <GatsbyImage image={bImage} alt={name} title="Click me!" />
                </div>
            </div>
            <div className={styles.info}>
                <Heading element="h3" size="m" font="main" weight="400" spacing="s">{name}</Heading>
                <Heading element="h4" size="s">{fn}</Heading>
            </div>


        </motion.div>
    );


};
import React, { useEffect, useRef } from 'react';
import * as styles from './graphicswrapper.module.scss';
import {motion} from 'framer-motion';
import cx from 'classnames';


export const GraphicsWrapper = ({children, ...props}) => {

    // define classes
    const classname = cx(
        styles.wrapper
    );

    const appRef = useRef(null);

    const variants = {
        hidden: {
            opacity: 0
        },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: .2,
            }
        }
      }

    const item = {
        hidden: {
            scale: 0
        },
        show: {
            scale: 1,
            transition: {
                duration: 1,
                type: "spring",
                stiffness: 700,
                damping: 30
            }
        },
        hover: {
            scale: 1.1,
            transition: {
                duration: 1,
                type: "spring",
                stiffness: 700,
                damping: 30
            }
        }
      }


    useEffect(() => {

        if (typeof window !== "undefined") {
            // const PIXI = require('pixi.js');
            const appCanvas = appRef.current;
        }

    }, [])

    // render html
    return (
        <div className={classname} ref={appRef} >

        <motion.div variants={variants} initial="hidden" animate="show" className={styles.inner} ref={appRef} >
            <motion.img
                drag
                dragElastic={0.05}
                dragConstraints={appRef}
                variants={item}
                src="/images/mouth.png"
                className={styles.mouth}/>
            <motion.img
                drag
                dragElastic={0.05}
                dragConstraints={appRef}
                variants={item}
                src="/images/mouth.png"
                className={styles.mouth}/>
            <motion.img
                drag
                dragElastic={0.05}
                dragConstraints={appRef}
                variants={item}
                src="/images/mouth.png"
                className={styles.mouth}/>
            <motion.img
                drag
                dragElastic={0.05}
                dragConstraints={appRef}
                variants={item}
                src="/images/mouth.png"
                className={styles.mouth}/>
            <motion.img
                drag
                dragElastic={0.05}
                dragConstraints={appRef}
                variants={item}
                src="/images/mouth.png"
                className={styles.mouth}/>
            <motion.img
                drag
                dragElastic={0.05}
                dragConstraints={appRef}
                variants={item}
                src="/images/mouth.png"
                className={styles.mouth}/>
        </motion.div>


            {/* <img className={styles.left} src="/images/mouths.png" />
            <img className={styles.right} src="/images/mouths.png" /> */}

            {/* <div className={styles.left}></div>
            <div className={styles.right}></div> */}
           {children}
        </div>
    );

};
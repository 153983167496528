import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {HelmetDatoCms} from 'gatsby-source-datocms';

import {graphql, Link} from 'gatsby';

import {StructuredText} from 'react-datocms';

import ContentBlock from "../components/02_molecules/ContentBlock/ContentBlock";
import ContentBlockItem from "../components/02_molecules/ContentBlock/ContentBlockItem";

import * as pages from '../components/03_organisms/Pages/pages.module.scss';
import {Helmet} from "react-helmet";

import shuffle from 'shuffle-array';

import Section from "../components/03_organisms/Section/Section";
import Slider from "../components/03_organisms/Slider/Slider";
import {Employee} from "../components/02_molecules/Employee/Employee";
import {Breadcrumb} from "../components/02_molecules/Breadcrumb/Breadcrumb";
import {BreadcrumbItem} from "../components/02_molecules/Breadcrumb/BreadcrumbItem";
import {Quicklinks} from "../components/03_organisms/Quicklinks/Quicklinks";
import {GraphicsWrapper} from "../components/03_organisms/GraphicsWrapper/GraphicsWrapperFramer";

import {PageTransition} from "../components/03_organisms/PageTransition/PageTransition";
import FadeInUp from "../components/01_atoms/FadeInUp/FadeInUp";
import {Heading} from "../components/01_atoms/Heading/Heading";

import * as styles from './styles/about.module.scss';

import {gsap} from "gsap/all";
import Wrapper from '../components/03_organisms/Wrapper/Wrapper';
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";


const About = ({data, pageContext, ...props}) => {

    // read language
    const {t} = useTranslation();

    shuffle(data.allDatoCmsEmployee.edges);

    useEffect(() => {


        const items = document.querySelectorAll('.customTitle mark');
        items.forEach((item) => {

            let newItem = item.innerHTML;

            switch (item.innerHTML) {
                case "oo":
                    newItem = newItem.replace("oo", '<img src="/images/eyes.svg" />');
                    break;
                case "b":
                    newItem = newItem.replace("b", '<img src="/images/furB.png" />');
                    break;
                case "TEAM":
                    newItem = newItem.replace("TEAM", '<span class="' + styles.underline + '">TEAM</span> <img src="/images/globeB.svg" />');
                    break;
            }

            item.innerHTML = newItem;
        })

    }, []);

    function onScrollDownClick(e) {
        e.preventDefault();
        gsap.to(window, {duration: .5, scrollTo: '#scrollToSection'});
    }

    function replaceChars(string) {

        return string;
    }


    // render html
    return (
        <Wrapper>
            <HeadInject />

            <PageTransition>
                <Helmet bodyAttributes={{class: pages.about}}/>
                <HelmetDatoCms seo={data.datoCmsAbout.seoMetaTags}/>


                {/* <Heading size="xxl" element="h1">{data.datoCmsAbout.title}</Heading> */}

                <GraphicsWrapper>

                    <Section content={false}>
                        <Breadcrumb>
                            <BreadcrumbItem to="/about" title="About" active/>
                        </Breadcrumb>
                    </Section>

                    <Section content={false}>
                        <div className={styles.intro}>
                            <div className={styles.inner}>
                                <FadeInUp><Heading element="h2" size="xxl" font="special10" uppercase align="center"><span className="customTitle"><StructuredText
                                    data={data.datoCmsAbout.intro}></StructuredText></span></Heading></FadeInUp>
                                <FadeInUp delay=".5"><a className={styles.scroll} href="#" onClick={onScrollDownClick}>Scroll down</a></FadeInUp>
                            </div>
                        </div>
                    </Section>
                </GraphicsWrapper>


                <div id="scrollToSection"></div>

                {data.datoCmsAbout.contentBlocks.map((contentBlock, index) => (
                    <div key={index} style={{backgroundColor: contentBlock.backgroundColor.hex}}>
                        <Section>
                            <FadeInUp delay={.25 + index * .25}>
                                <ContentBlock fullwidth={contentBlock.fullwidth} blockStyling={contentBlock.blockStyling}>
                                    <ContentBlockItem>
                                        <span className="customTitle"><StructuredText data={contentBlock.title}></StructuredText></span>
                                        <StructuredText data={contentBlock.text}></StructuredText>
                                    </ContentBlockItem>
                                </ContentBlock>
                            </FadeInUp>
                        </Section>
                    </div>
                ))}


                <Slider>
                    {data.allDatoCmsEmployee.edges.map(({node: teamMember}, index) => (
                        <Employee key={index} name={teamMember.name} fn={teamMember.function} image={teamMember.image.gatsbyImageData} bImage={teamMember.bImage.gatsbyImageData}/>
                    ))}
                </Slider>

                <Quicklinks title={t('quicklinks_jobs_title')} background="white">
                    <Link to="/jobs">{t('quicklinks_jobs_cta')}</Link>
                </Quicklinks>


            </PageTransition>
        </Wrapper>
    )
}

export default About;

export const query = graphql`
  query AboutQuery{
	datoCmsAbout {
		seoMetaTags {
			...GatsbyDatoCmsSeoMetaTags
		}
		contentBlocks {
			... on DatoCmsTitleText {
				fullwidth
				blockStyling
				backgroundColor{
					hex
				}
				text {
					value
				}
				title{
					value
				}
			}
		}
		title
		intro{
			value
		}
	  }
	  allDatoCmsEmployee {
		edges {
		  node {
			function
			name
			bImage {
			  gatsbyImageData(width: 500)
			}
			image {
			  gatsbyImageData(width: 500)
			}
		  }
		}
	  }
  }
`